
import axios from './rest-axios';

const getUnConfirmYuYueStat = () => axios.get(`/manage/yuyue_stat/unconfirm`, {});
const postConfirmYuYueStat = (date,user_id) => axios.post(`/manage/yuyue_stat/confirm`, {date,user_id});

export {
    getUnConfirmYuYueStat,
    postConfirmYuYueStat,
};
